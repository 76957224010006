import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useRef, useState } from 'react'
import IconoGraphy from '../../IconoGraphy'
import { useMediaQuery } from 'react-responsive'
import styles from './MobileHomeSearchBox.module.scss'
import {searchAnalyticEvent} from 'services/searchService'
import { useAppContext } from 'context/AppContext'

import ModalPopup from '../ModalPopup'
import { getScrollDepth, pushDataLayerEventHandler } from '@/utils/utils'
import { useRouter } from 'next/router'
import MobileSearchV2 from '../mobileHomeSearch/mobileSearchV2'

import MobileCitySearch from '../mobileHomeSearch/mobileCitySearch'
import DistrictSearch from '../HomeSearchDistrict/DistrictSearch'

export default function MobileHomeSearchPanel(props) {
  const {
  wrapperOpen,
  inputError,
  setInputError,
  setInputValue,
  isMobileOnly,
  setWrapperOpen,
  toggleSearchableModal,
  dropRef,
  inputValue,
  tabs,
  searchableModalVisible,
  showSuggestion,
  errorPage,
  handlePropertyIdChange,
  changePropertyType
  } = props;
  const { t, lang } = useTranslation('translations')
  const {state, dispatch} = useAppContext();
  const {searchReducer} = state
  const buttonRef = useRef()
  const searchInputRef = useRef(null)
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const router = useRouter()
  const [ cityClick,setCityClick] = useState(false)
  const [ districtClick,setDistrictClick] = useState(false)

  useEffect(() => {
    dispatch({ type: 'SET_SCROLL_DEPTH', payload: getScrollDepth(0) });
    dispatch({type:'UPDATE_PROPERTYFOR',payload:"sale"})
  }, [])

  useEffect(() => {
    if(isMobile) {
      const checkIfClickedOutside = (e) => {
        if (inputError && buttonRef.current && !buttonRef.current.contains(e.target)) {
          setInputError(false)
          dispatch({ type: 'SET_IS_OUTSIDE_CLICK', payload: false })
        }
      }
      document.addEventListener('click', checkIfClickedOutside)
    }
  }, [inputError])

  const onFocusInput = () =>{
    searchAnalyticEvent({scrolled_depth:searchReducer?.scrollDepth},'','',window,lang,'search_box_tap')
    setWrapperOpen(true)
    if (isMobileOnly && toggleSearchableModal) {
      toggleSearchableModal();
      searchInputRef.current.blur();
    }
  }

  const actionFunction = (tabname)=>{
    let viewClass = 'srp_list';
    handlePropertyIdChange(null,tabname)
    changePropertyType(tabname === 'sale' ? 'sale' : 'rent')
    dispatch({type:'UPDATE_PROPERTYFOR_MSITE',payload:tabname})
    dispatch({type:'UPDATE_PROPERTYFOR',payload:tabname})
    const mapView = JSON.parse(localStorage.getItem('zonefilterClick'))
    const isHomePage = router.pathname === '/'
    const isMapView = mapView?.isMapView || false

    if (isMapView) {
      viewClass = 'srp_map';
    }
    const page = isHomePage ? 'Home Page' : viewClass
  
    const data = {
      // city: searchReducer?.selectedItem?.cityName[0] || '',
      service: tabname,
      // propertyCategory,
      // property_sub_category: searchReducer?.propertyType,
      placement: "Home page",
    }
    pushDataLayerEventHandler(window, lang, 'Change Service', page, data)
  }
  const handleCityClick = (cityBackClick=false) =>{
    if(cityBackClick){
      setCityClick(false)
    }else{
      setCityClick(true)
    }    
  }
 
  const handleDistrictClick = (districtBackClick=false) =>{    
    if(districtBackClick){
      setDistrictClick(false)
    }else{
      setDistrictClick(true)
    }
  }
 
 
  return (
    <>
    <div className={styles.searchSectionWrapper}>
      {tabs?.map((tabname, index) => (
        <div
          id = {tabname.key}
          key={index}
          className={searchReducer?.propertyFor === tabname.key ? `${styles.tabName} ${styles.selected}` : `${styles.tabName}`}
          onClick={() => {
            actionFunction(tabname.key);
          }}
        >
          {tabname?.translation}
        </div>
            
      ))}
    </div>
    <div id='msiteHomeSearch' ref={dropRef} className={styles.searchConatainer}>
      
      <div className={`${errorPage ? styles.autoSuggest_404 : ''} ${styles.suggestionSection}`}>
        <div
          className={`${styles.searchsection} ${(!showSuggestion || inputValue?.length == 1) && styles.bottomBdr
            }`}
        >
          <IconoGraphy iconClass='icon-search-new' fontSize='f_16' />
          <input
            ref={searchInputRef}
            type='text'
            name='searchBox'
            placeholder={t("Search.HOME_SEARCH")}
            value={''}
            className={styles.searchcity}
            onChange={(e) => {
              setInputValue(e.target.value.trimStart())
              setWrapperOpen(true)
            }}
            onFocus={() => {
              onFocusInput();
            }}
            autoComplete='off'
          />
          {inputError && <span className={styles.errorMsg}>{t('Search.PLEASE_ENTER_VALID_LOCATION')}</span>}
        </div>
        {/* for msite view */}
       { searchableModalVisible &&  <ModalPopup
        wrapperOpen={wrapperOpen}
          childComponent={
           <MobileSearchV2 
           {...props}  
           handleCityClick={()=>handleCityClick()} 
           handleDistrictClick={()=>handleDistrictClick()}/>
          }
        />
}
        {cityClick && (
        <ModalPopup
        wrapperOpen={wrapperOpen}
          childComponent={
            <MobileCitySearch
            {...props}
            toggleCityBackClick ={()=>handleCityClick(true)}
          />
          }
        />
      )

      }

    {districtClick && (
        <ModalPopup
        wrapperOpen={wrapperOpen}
          childComponent={
            <DistrictSearch
            {...props}
            toggleDistrictBackClick ={()=>handleDistrictClick(true)}
          />
          }
        />
      )

      }
      </div>
    </div>
    </>
  )
}
